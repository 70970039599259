import payload_plugin_2QvIPMwtuU from "/app/node_modules/.pnpm/@pinia+nuxt@0.10.1_pinia@2.3.1/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_FxU4ENc9fb from "/app/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.4_eslint@9.20.1_rollup@2.79.1_sass@1.85.0_stylelint@16.14.1_typescript@5.7.3_vite@5.4.14/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_qA1sLzArrH from "/app/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.4_eslint@9.20.1_rollup@2.79.1_sass@1.85.0_stylelint@16.14.1_typescript@5.7.3_vite@5.4.14/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_FiS8FDSHAn from "/app/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.4_eslint@9.20.1_rollup@2.79.1_sass@1.85.0_stylelint@16.14.1_typescript@5.7.3_vite@5.4.14/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_X4YhHitXIT from "/app/node_modules/.pnpm/nuxt-site-config@3.0.6_vite@5.4.14_vue@3.5.13/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_BHbVT9K1aG from "/app/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.4_eslint@9.20.1_rollup@2.79.1_sass@1.85.0_stylelint@16.14.1_typescript@5.7.3_vite@5.4.14/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_JD2GIbckJa from "/app/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.4_eslint@9.20.1_rollup@2.79.1_sass@1.85.0_stylelint@16.14.1_typescript@5.7.3_vite@5.4.14/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8hDadG7ZuV from "/app/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.4_eslint@9.20.1_rollup@2.79.1_sass@1.85.0_stylelint@16.14.1_typescript@5.7.3_vite@5.4.14/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_ZIdxPBXw75 from "/app/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.4_eslint@9.20.1_rollup@2.79.1_sass@1.85.0_stylelint@16.14.1_typescript@5.7.3_vite@5.4.14/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_KXo0O70RTd from "/app/node_modules/.pnpm/@pinia+nuxt@0.10.1_pinia@2.3.1/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_gtaXQRshkP from "/app/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.4_eslint@9.20.1_rollup@2.79.1_sass@1.85.0_stylelint@16.14.1_typescript@5.7.3_vite@5.4.14/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_T6mYWp62S3 from "/app/node_modules/.pnpm/nuxt-delay-hydration@1.3.8/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/app/.nuxt/pwa-icons-plugin.ts";
import pwa_client_4fzV4HWaAC from "/app/node_modules/.pnpm/@vite-pwa+nuxt@0.10.6_@vite-pwa+assets-generator@0.2.6_vite@5.4.14_workbox-build@7.3.0_workbox-window@7.3.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_client_ClurPU1eiy from "/app/node_modules/.pnpm/nuxt-gtag@2.1.0/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_client_eNelkHx7DD from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import umami_iN1DWXMIUx from "/app/node_modules/.pnpm/nuxt-umami@2.6.7/node_modules/nuxt-umami/plugins/umami.ts";
import _2_pouchdb_client_rNyFaRK3ZK from "/app/plugins/2.pouchdb.client.ts";
import axios_QMFgzss1s4 from "/app/plugins/axios.ts";
export default [
  payload_plugin_2QvIPMwtuU,
  revive_payload_client_FxU4ENc9fb,
  unhead_qA1sLzArrH,
  router_FiS8FDSHAn,
  _0_siteConfig_X4YhHitXIT,
  payload_client_BHbVT9K1aG,
  navigation_repaint_client_JD2GIbckJa,
  check_outdated_build_client_8hDadG7ZuV,
  chunk_reload_client_ZIdxPBXw75,
  plugin_vue3_KXo0O70RTd,
  components_plugin_KR1HBZs4kY,
  prefetch_client_gtaXQRshkP,
  nuxt_plugin_T6mYWp62S3,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_4fzV4HWaAC,
  plugin_client_ClurPU1eiy,
  plugin_client_eNelkHx7DD,
  umami_iN1DWXMIUx,
  _2_pouchdb_client_rNyFaRK3ZK,
  axios_QMFgzss1s4
]