<template>
  <NuxtPwaManifest />
  
  <DelayHydration>
    <Adsbygoogle
      class="adsbygoogle"
      style="display: block"
      ad-slot="4357125401"
      ad-format="auto"
      full-width-responsive="true"
    />
  </DelayHydration>

  <div class="container mb-main__container">
    <div v-if="notifs.notificaciones.length && showNotifs">
      <div class="bg-orange p-8">
        <div v-for="notif in notifs.notificaciones" :key="notif._id" class="flex items-center gap-4 justify-between text-[#211212]">
          <Icon name="line-md:bell" width="20" height="20" />
          <p class="text-sm leading-tight  font-semibold mr-auto" v-html="notif.titulo">
          </p>
          <button class="block" @click="hideNotifs">
            <Icon name="material-symbols:close" width="20" height="20" class="ml-8" />
          </button>
        </div>
          
      </div>
    </div>

    <NuxtPage class="mb-view" />

    <div v-if="installPWA" class="fixed bottom-0 z-10 w-full px-4 mb-60">
      <button
        class="bg-orange text-white px-8 py-4 uppercase text-xs font-bold rounded-sm shadow mb-4 w-full"
        @click="onClickInstallPWA"
      >
        <Icon name="material-symbols:install-mobile" width="16" height="16"></Icon>
        <span> Agregar <strong>MiBiotren</strong> al inicio </span>
      </button>
    </div>

    <nav class="mb-nav__container">
      <NuxtLink
        v-if="['biotren'].includes(String(servicio))"
        :to="`/${servicio}/saldo`"
        aria-label="Ir a mis tarjetas guardadas"
        title="Ir a mis tarjetas guardadas"
      >
        <Icon
          :name="
            route.path === '/saldo'
              ? 'mdi:credit-card-multiple'
              : 'mdi:credit-card-multiple-outline'
          "
          width="24"
          height="24"
          class="stroke-current incline"
          alt="Mis tarjetas"
        />
        <span class="hidden">Ir a mis tarjetas guardadas</span>
        <small
          :class="[
            'uppercase text-xs text-center block font-bold',
            route.path === '/saldo' && 'text-orange',
          ]"
          >Tarjetas</small
        >
      </NuxtLink>

      <NuxtLink
        :to="`/${servicio}${route.params.idsalida ? `/${route.params.idsalida}` : ''}${
          route.params.idllegada ? `/${route.params.idllegada}` : ''
        }`"
        aria-label="Ir a los horarios del biotren"
        title="Ir a los horarios del biotren"
      >
        <Icon
          :name="route.path === '/' ? 'ri:map-pin-2-fill' : 'ri:map-pin-2-line'"
          class="stroke-current"
          width="24"
          height="24"
          alt="Horarios Biotren"
        />
        <span class="hidden">Ir a los horarios del biotren</span>
        <small
          :class="[
            'uppercase text-xs text-center block font-bold',
            route.path === '/' && 'text-orange',
          ]"
          >Horarios</small
        >
      </NuxtLink>

      <NuxtLink
        v-if="['biotren'].includes(String(servicio))"
        :to="`/${servicio}/mapa`"
        aria-label="Ir a los mapas de estaciones"
        title="Ir a los mapas de estaciones"
      >
        <Icon name="mingcute:map-fill" width="24" height="24" alt="Mapa estaciones" />
        <span class="hidden">Ir a los mapas de estaciones</span>
        <small class="uppercase text-xs text-center block font-bold">Mapa</small>
      </NuxtLink>
    </nav>
  </div>
</template>

<script lang="ts" setup>
import { useWebWorkerFn } from "@vueuse/core";
import { useServiciosStore } from "~/store/servicios";
import { useHorarioStore } from "~/store/horarios";
import { useNotificacionesStore } from "~/store/notificaciones";

const route = useRoute();

const servs = useServiciosStore();
const horarios = useHorarioStore();
const notifs = useNotificacionesStore();

const showNotifs = ref(true);
const hideNotifs = () => {
  showNotifs.value = false;
};

const installPWA = ref();
const servicio = computed(() => route.params.servicio || "biotren");

const onClickInstallPWA = async () => {
  if (installPWA.value) {
    installPWA.value.prompt();
    const { outcome } = await installPWA.value.userChoice;
    if (outcome === "accepted") {
      console.log("User accepted the A2HS prompt");
    } else {
      console.log("User dismissed the A2HS prompt");
    }
    installPWA.value = null;
  }
};
const { workerFn } = useWebWorkerFn(
  async () => {
    const broadcast = new BroadcastChannel("PERCENT");

    const Horarios = new PouchDB("HorariosV4");
    const HorariosRemote = new PouchDB(`https://db.mibiotren.cl/horariosv4`);

    const [infoRemote, infoLocal] = await Promise.all([
      HorariosRemote.info(),
      Horarios.info(),
    ]);

    const sendPercent = (percent = 0) =>
      broadcast.postMessage({
        type: "PERCENT",
        percent,
      });

    const newHorarios = new Promise((res, rej) => {
      Horarios.replicate
        .from(HorariosRemote)
        .on("change", (info) => {
          const percent = (info.docs_written / infoRemote.doc_count) * 100;
          console.log({ percent });
          sendPercent(percent);
        })
        .on("complete", (data) => {
          sendPercent(100);
          res(data);
        })
        .on("error", (err) => {
          rej(new Error("Problema replicación"));
        });
    });

    return newHorarios;
  },
  {
    dependencies: ["https://cdnjs.cloudflare.com/ajax/libs/pouchdb/8.0.1/pouchdb.min.js"],
  }
);

await callOnce(async () => {
  return servs.populateServicios(true).catch((e) => console.log(e));
});

const percent = ref(0);

onMounted(async () => {
  const broadcast = new BroadcastChannel("PERCENT");
  const broadcastNotifs = new BroadcastChannel('NOTIFS');

  broadcast.addEventListener("message", (event) => {
    if (event.data?.type === "PERCENT") {
      percent.value = event.data.percent;
      horarios.updateFn(event.data.percent);
    }
    if(event.data?.type === 'NOTIFS') {
      // notifs.updateNotificaciones(event.data.notificaciones);
    }
  });

  window.addEventListener("beforeinstallprompt", (e) => {
    e.preventDefault();
    installPWA.value = e;
  });

  await workerFn().then(() => {
    return horarios.getAllHorarios();
  });
});

</script>

<style lang="scss">
body {
  @apply text-grey-darkest bg-grey-lighter;
  margin: 0;
}
#app {
  max-width: 100%;
  overflow-x: hidden;
}
.mb-view {
  max-width: 800px;
  margin: 0 auto;
  position: relative;
}

.monotype {
  font-family: "Roboto Mono", monospace;
}
.dark-mode body {
  @apply bg-gray-800 text-white;
}
.incline {
  transform: rotate(-12deg);
}
.mb-main__container {
  min-height: 100vh;
  @apply mx-auto relative;
}

.mb-nav__container {
  @apply fixed bottom-0 left-0 w-full border-t border-grey-lighter bg-white;

  @supports (display: grid) {
    @apply grid grid-cols-3;
  }

  a {
    transition: all 0.25s ease-in-out;
    @apply p-8 py-8 flex flex-col items-center;

    &.router-link-exact-active {
      svg {
        @apply text-orange;
      }
    }
  }

  svg {
    @apply mb-4;
  }
}
.dark-mode .mb-nav__container {
  @apply bg-gray-800 border-gray-700;

  svg {
    @apply text-gray-300;
  }

  .NuxtLink-exact-active {
    svg {
      @apply text-orange;
    }
  }
}

@keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotation {
  animation: rotation 3s linear infinite normal;
}

.mb-btn {
  @apply text-xs uppercase tracking-wider rounded-sm shadow flex px-8 py-4 leading-none items-center border border-grey-light bg-transparent;

  svg {
    @apply stroke-current ml-4;
  }

  &:active {
    @apply shadow-sm outline-none border-orange;
  }

  &.activo {
    @apply bg-orange text-white border-orange;
  }
}
.buscando {
  svg {
    animation: breathing 5s ease-out infinite normal;
    @apply text-orange;
  }
}

.dark-mode .mb-btn {
  @apply border-gray-700 text-gray-200;

  &.activo {
    @apply bg-orange text-white border-orange;
  }
}
</style>
